import {
  InstantMatchFragment,
  LobbiesFragment,
  MatchCompetitorFragment,
  UserFragment,
} from 'graphpl/core';

type NavigationProps = {
  action: 'NAVIGATE';
  source: string;
  data: {
    url: string;
    targetId?: string;
  };
};

type DismissModalProps = {
  action: 'DISMISS_MODAL';
  source: string;
};

type SendingDirectChallengeProps = {
  action: 'SENDING_DIRECT_CHALLENGE';
  source: string;
  data: {
    opponent: MatchCompetitorFragment;
    newMatchFlowEnabled: boolean;
  };
};

type RefetchUserProps = {
  action: 'REFETCH_USER';
  source: string;
};

type UpdateUserProps = {
  action: 'UPDATE_USER';
  source: string;
  user: UserFragment;
};

type LobbyUpdateProps = {
  action: 'LOBBY_UPDATE';
  source: string;
  data: LobbiesFragment;
};

type FindMatchProps = {
  action: 'FIND_MATCH';
  source: string;
  data: {
    newMatchFlowEnabled: boolean;
  };
};

type InstantMatchDetailsProps = {
  action: 'INSTANT_MATCH';
  source: string;
  data: {
    newMatchFlowEnabled: boolean;
    matchDeatils: InstantMatchFragment;
  };
};

type SearchingCardProps = {
  action: 'SEARCHING_CARD';
  source: string;
  data: {
    newMatchFlowEnabled: boolean;
  };
};

type MessageParentProps =
  | NavigationProps
  | DismissModalProps
  | RefetchUserProps
  | SendingDirectChallengeProps
  | FindMatchProps
  | InstantMatchDetailsProps
  | SearchingCardProps
  | UpdateUserProps
  | LobbyUpdateProps;

export const messageParent = (props: MessageParentProps) => {
  if (window?.parent?.postMessage) {
    window?.parent?.postMessage(JSON.stringify(props, null, 2), '*');
  }
  // @ts-expect-error
  if (window?.ReactNativeWebView?.postMessage) {
    // @ts-expect-error
    window?.ReactNativeWebView?.postMessage(JSON.stringify(props, null, 2));
  }
};
